<template>
	<v-menu
		ref="menu"
		v-model="menu2"
		:close-on-content-click="false"
		:nudge-right="40"
		:return-value.sync="time"
		:disabled="header.disabled"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="290px"
	>
		<template v-slot:activator="{ on, attrs }">
			<div v-on="on">
				<v-text-field
					v-model="time"
					:label="input.label"
					:disabled="header.disabled"
					append-icon="mdi-clock-time-four-outline"
					outlined
					hide-details
					readonly
					v-bind="attrs"
				></v-text-field>
			</div>
		</template>
		<v-time-picker
			v-if="menu2"
			v-model="time"
			full-width
			ampm-in-title
			@click:minute="$refs.menu.save(time)"
		></v-time-picker>
	</v-menu>
</template>
<script>
import moment from 'moment';
export default {
	name: 'CTimePicker',
	props: {
		input: Object,
	},
	data: () => ({ menu2: false }),
	computed: {
		header() {
			return this.$store.getters['inspection/getHeaderById'](this.input.id);
		},
		time: {
			get() {
				return this.header.value || null;
			},
			set(value) {
				this.$store.dispatch('inspection/updateInputHeader', {
					value,
					id: this.input.id,
				});
			},
		},
	},
	created() {
		if (!this.input.value) {
			this.time = moment().format('HH:mm');
		}
	},
};
</script>
